import Contact from "./Contact"

function Footer() {
    return (
        <footer>
            <Contact />
        </footer>
    )
}

export default Footer